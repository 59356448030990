import { createStore } from 'vuex'

import api from '@/services/api'
import AuthService from '@/services/auth.service'
import TokenService from '@/services/token.service'
import { decodeToken } from 'jsontokens'
import { PaginationBody, PaginationHeader, PostFilterMap } from '@/types/Misc'
import { Claim } from '@/types/Claims'
import { mapOptionsToHeaders } from '@/helpers/table-utils'
import { BlendedCareUser, DeletionUser } from '@/types/User'
import { PaginHeaderDefault } from '@/consts/PaginationConst'
import { Group } from '@/types/Groups'
import { Voucher } from '@/types/Voucher'
import { AxiosResponse } from 'axios'
import { parsePostFilters, parsePostPaginationParams, parsePostSorting } from "@/helpers/api";

const user: any = TokenService.getUserTokenData()

const store = createStore({
  state: {
    user: user,
    userType: '' as string,
    status: {
      loggedIn: !!user as boolean,
      loading: false as boolean
    },
    pagination: {
      voucher: { ...PaginHeaderDefault } as PaginationHeader,
      blendedCare: { ...PaginHeaderDefault } as PaginationHeader,
      deletion: { ...PaginHeaderDefault } as PaginationHeader,
      insurers: { ...PaginHeaderDefault } as PaginationHeader
    },
    groupList: [] as Group[],
    voucherList: [] as Voucher[],
    blendedCareList: [] as BlendedCareUser[],
    deletionList: [] as DeletionUser[],
    insurerList: [],
    vouchersCount: 0 as number,
    blendedCareCount: 0 as number,
    deletionCount: 0 as number,
    isApiReady: false as boolean
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.userType = ''
      state.user = null
    },
    refreshUser(state, userData) {
      state.status.loggedIn = true;
      state.user = { ...userData }
    },
    cleanUser(state) {
      state.status.loggedIn = false
      state.userType = ''
      state.user = null
      TokenService.removeUserTokenData()
    },
    setUserRole(state, role) {
      state.userType = role
    },
    setGroupList(state, groupList) {
      state.groupList = groupList
    },
    setVouchersBody(state, paginationData: PaginationBody) {
      state.pagination.voucher = {
        ...mapOptionsToHeaders(paginationData)
      }
    },
    setVoucherList(state, voucherList) {
      state.voucherList = voucherList
    },
    setVouchersCount(state, count) {
      state.vouchersCount = count
    },
    setInsurersBody(state, paginationData: PaginationBody) {
      state.pagination.insurers = {
        ...mapOptionsToHeaders(paginationData)
      }
    },
    setInsurerList(state, insurerList) {
      state.insurerList = insurerList
    },
    setBlendedCareBody(state, paginationData: PaginationBody) {
      state.pagination.blendedCare = {
        ...mapOptionsToHeaders(paginationData)
      }
    },
    setBlendedCareList(state, blendedCareList) {
      state.blendedCareList = blendedCareList
    },
    setBlendedCareCount(state, count) {
      state.blendedCareCount = count
    },
    setDeletionBody(state, paginationData: PaginationBody) {
      state.pagination.deletion = {
        ...mapOptionsToHeaders(paginationData)
      }
    },
    setDeletionList(state, deletionList) {
      state.deletionList = deletionList
    },
    setDeletionCount(state, count) {
      state.deletionCount = count
    },
    setApiReady(state) {
      state.isApiReady = true
    },
    setLoading(state, value: boolean) {
      state.status.loading = value
    }
  },
  actions: {
    login({ commit }, {user, lastStep}) {
      return AuthService.login(user, lastStep).then(
        user => {
          if (lastStep) {
            commit('loginSuccess', user)
            commit('setUserRole')
          }
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      );
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    setLoading({ commit }, value: boolean) {
      commit('setLoading', value)
    },
    refreshUser({ commit }, userData) {
      commit('refreshUser', userData)
    },
    cleanUser({ commit }) {
      commit('cleanUser')
    },
    fetchGroups({ commit }) {
      const body: PaginationBody = {
        pageSize: 200,
        pageNumber: 1,
        searchValue: '',
        orderBy: '',
        descending: true
      }
      api.put('manager/group/filter', JSON.stringify(body)).then(res => {
        commit('setGroupList', res?.data.data.list)
      }).catch((err: any) => console.log(err.message))
    },
    fetchVouchers({ commit }, paginationData: PaginationBody) {
      commit('setVouchersBody', paginationData)
      api.get('manager/voucher/filter', {
        headers: {
          ...this.state.pagination.voucher
        }
      }).then((res: AxiosResponse) => {
        commit('setVoucherList', res?.data.data.list)
        commit('setVouchersCount', res?.data.data.pageInfo?.totalCount)
      }).catch((err: any) => console.log(err.message))
    },
    fetchInsurers({ commit }, paginationData: PaginationBody) {
      commit('setInsurersBody', paginationData)
      api.get('manager/insurer/filter', {
        headers: {
          ...this.state.pagination.insurers
        }
      }).then((res: AxiosResponse) => {
        commit('setInsurerList', res?.data.data)
      }).catch((err: any) => console.log(err.message))
    },
    fetchBlendedCare({ commit }, { paginationData, filters = {} }: { paginationData: PaginationBody, filters: PostFilterMap }) {
      const sorting = parsePostSorting(paginationData)
      const paginationParams = parsePostPaginationParams(paginationData)
      const filtering = parsePostFilters(filters)

      commit('setBlendedCareBody', paginationData)
      api.post('telecoach/blendedcare/filter', {
        sorting,
        ...filtering,
      }, {
        headers: paginationParams
      }).then((res: AxiosResponse) => {
        commit('setBlendedCareList', res?.data.data.list)
        commit('setBlendedCareCount', res?.data.data.pageInfo?.totalCount)
      })
    },
    fetchDeletionUsers({ commit }, paginationData: PaginationBody) {
      commit('setDeletionBody', paginationData)
      api.get('manager/userrequest/filter', {
        headers: {
          ...this.state.pagination.deletion
        }
      }).then((res: AxiosResponse) => {
        commit('setDeletionList', res?.data.data.list)
        commit('setDeletionCount', res?.data.data.pageInfo?.totalCount)
      })
    },
    setUserRole({ commit }) {
      const token = TokenService.getLocalAccessToken()
      if (token) {
        const claims: Claim = decodeToken(token) as Claim
        if (typeof(claims?.payload.role) == 'string') {
          commit('setUserRole', claims?.payload.role)
        } else {
          commit('setUserRole', claims?.payload.role[0])
        }
      }
    },
    apiReady({ commit }) {
      commit('isApiReady')
    }
  }
})

export default store