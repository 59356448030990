import { PaginationBody, PaginationHeader } from "@/types/Misc"
import { format } from 'date-fns'

export function mapOptionsToHeaders(options: PaginationBody): PaginationHeader {
  const headers: PaginationHeader = {} as PaginationHeader

  headers['X-Desc'] = options.descending
  headers['X-Order'] = options.orderBy
  headers['X-PageNumber'] = options.pageNumber
  headers['X-PageSize'] = options.pageSize
  headers['X-Query'] = options.searchValue

  return headers
}

export function parseDateShortFormat(timestamp: number | undefined): string {
  return timestamp ? format(new Date(timestamp * 1000), 'yyyy-MM-dd') : '-'
}

export function parseDateLongFormat(timestamp: number | null | undefined): string {
  return timestamp ? format(new Date(timestamp * 1000), 'dd-MM-yyyy HH:mm:ss') : '-'
}

export function parseDateLongFormatFromString(stringDate: string): string {
  return stringDate ? format(new Date(stringDate), 'dd-MM-yyyy HH:mm:ss') : '-'
}