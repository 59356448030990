import {
  PostFilter,
  PostFilterMap,
  PostFilterParsed,
  PaginationBody,
} from "@/types/Misc";

export const parsePostFilters = (postFilterMap: PostFilterMap) =>
  Object.values(postFilterMap).reduce(
    (acc: Record<string, PostFilterParsed>, cur: PostFilter) => {
      if (cur.type && cur.value) {
        if (!acc[cur.type]) {
          acc[cur.type] = [];
        }

        acc[cur.type].push({
          value: cur.value,
          fieldName: cur.name,
        });
      }

      return acc;
    },
    {}
  );

export const parsePostSorting = (paginationData: PaginationBody) => ({
  fieldname: paginationData.orderBy,
  descending: paginationData.descending,
});

export const parsePostPaginationParams = (paginationData: PaginationBody) => ({
  "X-PageNumber": paginationData.pageNumber,
  "X-PageSize": paginationData.pageSize,
  "X-Query": paginationData.searchValue,
});
