const ContactStage = {
  Untouched: "Untouched",
  TouchedInAppointmentSetting: "TouchedInAppointmentSetting",
  TouchedNotReached: "TouchedNotReached",
  TouchedDeclined: "TouchedDeclined",
  ProgramFinished: "ProgramFinished",
  ProgramPostoponed: "ProgramPostoponed",
} as const;

export { ContactStage };
